<script setup>


import DropDown from '@/Components/Form/DropDown.vue'
import {VueSignaturePad} from 'vue-signature-pad';
import {defineEmits, onMounted, watch, reactive, toRaw, ref, computed} from "vue";
import axios from 'axios';
import {TrashIcon} from "@heroicons/vue/20/solid";
import '@vuepic/vue-datepicker/dist/main.css';
import moment from "moment/moment";


const emit = defineEmits(['remove', 'reloadInfo', 'updateFormData'])

const props = defineProps({
  formData: {
    type: Object,
    required: false,
    default: null,
  },
  hasPrimaryEin: {
    type: Boolean,
    required: false,
    default: false,
  },

})

const validationErrors = ref('US');
const country = ref('US');


const corporationValues = [
  {id: 1, name: 'C-Corp'},
  {id: 2, name: 'S-Corp'}
];
// const signaturePadFss4 = ref(null);
// const signaturePadF8821 = ref(null);


// const form = reactive({
//   ein_first_name: null,
//   ein_last_name: null,
//   ein_county: null,
//   ein_ssn: null,
//   ein_member_number: null,
//   ein_merchandise: null,
//   ein_date_start: null,
//   ein_entity_type: null,
//   ein_before: 0,
//   ein_before_specified: null,
//   ein_reason: null,
//   ein_activity: null,
//   ein_further_info: null,
//   ein_entity_type_specified: null,
//   ein_entity_type_corporation_specified: null,
//   question: {heavy_vehicle: null, gambling: null, w2: null, guns: null, quarterly: null},
//   signature_fss4: null,
//   signature_f8821: null,
//
//
//   errors: {
//     ein_first_name: null,
//     ein_last_name: null,
//     ein_county: null,
//     ein_ssn: null,
//     ein_member_number: null,
//     ein_merchandise: null,
//     ein_date_start: null,
//     ein_entity_type: null,
//     ein_before: null,
//     ein_before_specified: null,
//     ein_reason: null,
//     ein_activity: null,
//     ein_activity_specified: null,
//     ein_further_info: null,
//     ein_entity_type_specified: null,
//     ein_entity_type_corporation_specified: null,
//   }
// });

const form = props.formData;



const submitForm = () => {
  emit('updateFormData', form);
}

const remove = () => {
  emit('remove', true);
}

const handleEntityTypeCorporationSpecified = (value) => {
  form.ein_entity_type_corporation_specified = value;
}


watch(form, (newForm) => {
  emit('updateFormData', newForm);

}, {deep: true});



onMounted(() => {





});

</script>

<template>


  <div class="mt-10 border border-gray-200 pt-10 p-3">
    <div class="bg-emerald-500 text-white">
      <div v-if="!hasPrimaryEin" class="float-right flex ">
        <button type="button"
                class="-m-0.0 flex p-2.5  hover:text-indigo-300 bg-emerald-500 text-white">

          <span class="sr-only">Remove</span>
          <TrashIcon @click="remove()" class="h-7 w-7" aria-hidden="true"/>
        </button>
      </div>
      <h2 class="text-xl font-bold uppercase text-center">EIN Registration Information</h2>
      <h3 class="text-md font-medium text-center">(Required if ordering the EIN Registration Option)</h3>
    </div>
    <h2 class="text-md font-medium mt-2">1. Responsible Party</h2>
    <div class="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">

      <div>
        <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': form.errors['ein.ein_first_name']}" v-model="form.ein.ein_first_name"
                 type="text" id="first-name" name="first-name" autocomplete="given-name"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
          <div v-if="form.errors['ein.ein_first_name']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.ein_first_name']"
                  :key="index">{{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': form.errors['ein.ein_last_name']}" v-model="form.ein.ein_last_name"
                 type="text" id="last-name" name="last-name" autocomplete="family-name"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
          <div v-if="form.errors['ein.ein_last_name']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.ein_last_name']" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="county" class="block text-sm font-medium text-gray-700">County in {{ form.jurisdictionName }}</label>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': form.errors['ein.ein_county']}" v-model="form.ein.ein_county"
                 type="text" name="county" id="county"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
          <div v-if="form.errors['ein.ein_county']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.ein_county']" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="address" class="block text-sm font-medium text-gray-700">
          SSN, ITIN, or EIN of Responsible Party</label>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': form.errors['ein.ein_ssn']}" v-model="form.ein.ein_ssn" type="text"
                 name="ssn" id="ssn" autocomplete="street-address"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                 placeholder="XXX-XX-XXXX"/>
          <div v-if="form.errors['ein.ein_ssn']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.ein_ssn']" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="sm:col-span-2">
        <h2 class="text-md font-medium mt-2">2. Type of Entity (Select One)</h2>
      </div>
      <div class="sm:col-span-2">
        <div class="space-y-5">
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_entity_type" value="LLC" id="medium"
                     aria-describedby="medium-description" name="ein_entity_type" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Limited Liability Company</label>
            </div>
          </div>
          <div class="relative flex items-start">

            <div class="flex h-6 items-center">

              <input v-model="form.ein.ein_entity_type" value="corporation" id="small" aria-describedby="small-description"
                     name="ein_entity_type" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6 w-2/4">
              <label for="medium" class="font-medium text-gray-900">Corporation (specify)</label>
            </div>

            <DropDown
                class="w-full"
                v-if="form.ein.ein_entity_type==='corporation'"
                @update:modelValue="handleEntityTypeCorporationSpecified"
                :values="corporationValues"
            ></DropDown>
          </div>


          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_entity_type" value="church" id="medium"
                     aria-describedby="medium-description" name="ein_entity_type" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Church or Church Controller Organization</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_entity_type" value="other_non_profit" id="medium"
                     aria-describedby="medium-description" name="ein_entity_type" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Other Non-Profit</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_entity_type" value="sole_proprietorship" id="small"
                     aria-describedby="small-description" name="ein_entity_type" type="radio" checked
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="small" class="font-medium text-gray-900">Sole Proprietor</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_entity_type" value="trust" id="medium"
                     aria-describedby="medium-description" name="ein_entity_type" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Trust</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_entity_type" value="partnership" id="medium"
                     aria-describedby="medium-description" name="ein_entity_type" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Partnership</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">

              <input v-model="form.ein.ein_entity_type" value="other" id="small" aria-describedby="small-description"
                     name="ein_entity_type" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6 w-2/4">
              <label for="medium" class="font-medium text-gray-900">Other (specify)</label>
            </div>
            <input
                v-if="form.ein.ein_entity_type==='other'"
                :class="{'border-2 border-red-500': form.errors.ein_entity_type_specified}"
                v-model="form.ein.ein_entity_type_specified" type="text"
                name="ein_activity_specified" id="ein_activity_specified" autocomplete="postal-code"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter Entity Type"/>

          </div>
        </div>
      </div>

      <div class="sm:col-span-2">
        <h2 class="text-md font-medium mt-2">3. Number of Owners or Members?</h2>

      </div>
      <div class="sm:col-span-2">
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': form.errors.ein_member_number}"
                 v-model="form.ein.ein_member_number" type="text"
                 name="ein_member_number" id="ein_member_number" autocomplete="ein_member_number"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                 placeholder="1"/>
          <div v-if="form.errors.ein_member_number" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors.address" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="sm:col-span-2">
        <h2 class="text-md font-medium mt-2">4. Reason for Applying (Select One)</h2>

      </div>
      <div class="sm:col-span-2">
        <div class="space-y-5">
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_reason" value="Started new business" id="small"
                     aria-describedby="small-description" name="reason" type="radio" checked
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="small" class="font-medium text-gray-900">Started new business</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_reason" value="Hired employees" id="medium"
                     aria-describedby="medium-description" name="reason" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Hired employees</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_reason" value="Banking purpose" id="medium"
                     aria-describedby="medium-description" name="reason" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Banking purpose</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_reason" value="Purchased ongoing business" id="medium"
                     aria-describedby="medium-description" name="reason" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Purchased ongoing business</label>
            </div>
          </div>


        </div>
      </div>
      <div class="sm:col-span-2">
        <h2 class="text-md font-medium mt-2">5. Date Business Started</h2>
      </div>
      <div>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': form.errors.ein_date_start}" v-model="form.ein.ein_date_start"
                 type="text"
                 name="postal-code" id="postal-code" autocomplete="postal-code"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                 placeholder="MM/DD/YEAR"/>
          <div v-if="form.errors.ein_date_start" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors.ein_date_start"
                  :key="index">{{
                  error
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="sm:col-span-2">
        <h2 class="text-md font-medium mt-2">6. Check one box that best describes the principal activity of your
          business.</h2>
      </div>


      <div class="sm:col-span-2">

        <div class="space-y-5">
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_activity" value="Construction" id="small"
                     aria-describedby="small-description" name="act" type="radio" checked
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="small" class="font-medium text-gray-900">Construction</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_activity" value="Rental & leasing" id="small"
                     aria-describedby="medium-description" name="act" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Rental & leasing</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_activity" value="Transportation & warehousing" id="small"
                     aria-describedby="medium-description" name="act" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Transportation & warehousing</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_activity" value="Real estate" id="small"
                     aria-describedby="medium-description" name="act" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Real estate</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_activity" value="Finance & insurance" id="small"
                     aria-describedby="medium-description" name="act" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Finance & insurance</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_activity" value="Health care & social assistance" id="small"
                     aria-describedby="medium-description" name="act" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Health care & social assistance</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_activity" value="Manufacturing" id="small"
                     aria-describedby="medium-description" name="act" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Manufacturing</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_activity" value="Wholesale-agent/broker" id="small"
                     aria-describedby="medium-description" name="act" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Wholesale-agent/broker</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_activity" value="Accommodation & food service" id="small"
                     aria-describedby="medium-description" name="act" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Accommodation & food service</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_activity" value="Wholesale-other" id="small"
                     aria-describedby="medium-description" name="act" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Wholesale-other</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_activity" value="Retail" id="small" aria-describedby="medium-description"
                     name="act" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">Retail</label>
            </div>
          </div>
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">

              <input v-model="form.ein.ein_activity" value="Other" id="small" aria-describedby="small-description"
                     name="act" type="radio" checked
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">

            </div>
            <div class="ml-3 text-sm leading-6 w-2/4">
              <label for="medium" class="font-medium text-gray-900">Other (specify)</label>
            </div>
            <input :class="{'border-2 border-red-500': form.errors['ein.ein_activity_specified']}"
                   v-model="form.ein.ein_activity_specified" type="text"
                   name="ein_activity_specified" id="ein_activity_specified" autocomplete="postal-code"
                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                   placeholder="Enter Business Activity"/>

          </div>
          <div v-if="form.errors['ein.ein_activity_specified']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.ein_activity_specified']"
                  :key="index">{{
                  error
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="sm:col-span-2">
        <h2 class="text-md font-medium mt-2">7. If you offer a service, which type of Service do you offer? If you sell
          merchandise, what principle line of merchandise do
          you sell?</h2>
      </div>
      <div>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': form.errors.ein_merchandise}"
                 v-model="form.ein.ein_merchandise" type="text"
                 name="postal-code" id="postal-code" autocomplete="postal-code"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                 placeholder="Service or Merchandise Type"/>
          <div v-if="form.errors.ein_merchandise" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors.ein_merchandise"
                  :key="index">{{
                  error
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>


      <div class="sm:col-span-2 mt-6">
        <h2 class="text-md font-medium mt-2">8. Has the responsible party ever applied for a company EIN
          before?</h2>
      </div>
      <div class="sm:col-span-2">


        <div class="space-y-5">

          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_before" value="0" id="medium" aria-describedby="medium-description"
                     name="ein_before" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="medium" class="font-medium text-gray-900">No</label>
            </div>
          </div>

          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="form.ein.ein_before" value="1" id="medium" aria-describedby="medium-description"
                     name="ein_before" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3 text-sm leading-6 w-2/4">
              <label for="medium" class="font-medium text-gray-900">Yes</label>
            </div>
            <input :class="{'border-2 border-red-500': form.errors['ein.ein_before_specified']}"
                   v-model="form.ein.ein_before_specified" type="text"
                   name="postal-code" id="postal-code" autocomplete="postal-code"
                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                   placeholder="If Yes, enter EIN"/>

          </div>
          <div v-if="form.errors['ein.ein_before_specified']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.ein_before_specified']"
                  :key="index">{{
                  error
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>


      <div class="sm:col-span-2 mt-6">
        <h2 class="text-md font-medium mt-2">9. Briefly, within 2-3 sentences, please tell us what your business
          does. </h2>
      </div>

      <div class="sm:col-span-2">
        <div class="space-y-5">
                  <textarea :class="{'border-2 border-red-500': form.errors['ein.ein_further_info']}"
                            rows="4"
                            v-model="form.ein.ein_further_info"
                            name="further-info" id="further-info"
                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Describe what your business does."></textarea>
        </div>
      </div>

      <div class="sm:col-span-2 mt-6">
        <h2 class="text-md font-medium mt-2">10. Answer these yes or no questions about your business.</h2>
      </div>

      <div class="lg:col-span-2 border-2 bg-white rounded p-4 space-y-8">
        <div class="lg:col-span-2" :class="{'border-2 border-red-500 p-2': form.errors['ein.question.heavy_vehicle']}">
          <div class="w-full">
            Does your business own a highway motor vehicle with a taxable gross weight of 55,000 pounds or more?
          </div>
          <div class="flex items-center space-x-8 mt-2">
            <div class="flex items-center">
              <label for="yes-option" class="font-medium text-gray-900 mr-2"
                     :class="{'text-red-600': form.errors['ein.question.heavy_vehicle']}">Yes</label>
              <input v-model="form.ein.question.heavy_vehicle" value="1" id="yes-option" name="heavy_vehicle" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>

            <div class="flex items-center">
              <label for="no-option" class="font-medium text-gray-900 mr-2"
                     :class="{'text-red-600': form.errors['ein.question.heavy_vehicle']}">No</label>
              <input v-model="form.ein.question.heavy_vehicle" value="0" id="no-option" name="heavy_vehicle" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
          </div>
          <div v-if="form.errors['ein.question.heavy_vehicle']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.question.heavy_vehicle']"
                  :key="index">{{
                  error
                }}
              </li>
            </ul>
          </div>
        </div>

        <div class="lg:col-span-2" :class="{'border-2 border-red-500 p-2': form.errors['ein.question.gambling']}">
          <div class="w-full">
            Does your business involve gambling/wagering?
          </div>
          <div class="flex items-center space-x-8 mt-2">
            <div class="flex items-center">
              <label for="yes-option" class="font-medium text-gray-900 mr-2"
                     :class="{'text-red-600': form.errors['ein.question.gambling']}">Yes</label>
              <input v-model="form.ein.question.gambling" value="1" id="yes-option" name="gambling" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>

            <div class="flex items-center">
              <label for="no-option" class="font-medium text-gray-900 mr-2"
                     :class="{'text-red-600': form.errors['ein.question.gambling']}">No</label>
              <input v-model="form.ein.question.gambling" value="0" id="no-option" name="gambling" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
          </div>
          <div v-if="form.errors['ein.question.gambling']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.question.gambling']"
                  :key="index">{{
                  error
                }}
              </li>
            </ul>
          </div>
        </div>

        <div class="lg:col-span-2" :class="{'border-2 border-red-500 p-2': form.errors['ein.question.quarterly']}">
          <div class="w-full">
            Does your business need to file Form 720 (Quarterly Federal Excise Tax Return)?
          </div>
          <div class="flex items-center space-x-8 mt-2">
            <div class="flex items-center">
              <label for="yes-option" class="font-medium text-gray-900 mr-2"
                     :class="{'text-red-600': form.errors['ein.question.quarterly']}">Yes</label>
              <input v-model="form.ein.question.quarterly" value="1" id="yes-option" name="quarterly" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>

            <div class="flex items-center">
              <label for="no-option" class="font-medium text-gray-900 mr-2"
                     :class="{'text-red-600': form.errors['ein.question.quarterly']}">No</label>
              <input v-model="form.ein.question.quarterly" value="0" id="no-option" name="quarterly" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
          </div>
          <div v-if="form.errors['ein.question.quarterly']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.question.quarterly']"
                  :key="index">{{
                  error
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="lg:col-span-2" :class="{'border-2 border-red-500 p-2': form.errors['ein.question.guns']}">
          <div class="w-full">
            Does your business sell or manufacture alcohol, tobacco, or firearms?
          </div>
          <div class="flex items-center space-x-8 mt-2">
            <div class="flex items-center">
              <label for="yes-option" class="font-medium text-gray-900 mr-2"
                     :class="{'text-red-600': form.errors['ein.question.guns']}">Yes</label>
              <input v-model="form.ein.question.guns" value="1" id="yes-option" name="guns" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>

            <div class="flex items-center">
              <label for="no-option" class="font-medium text-gray-900 mr-2"
                     :class="{'text-red-600': form.errors['ein.question.guns']}">No</label>
              <input v-model="form.ein.question.guns" value="0" id="no-option" name="guns" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
          </div>
          <div v-if="form.errors['ein.question.guns']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.question.guns']"
                  :key="index">{{
                  error
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="lg:col-span-2" :class="{'border-2 border-red-500 p-2': form.errors['ein.question.w2']}">
          <div class="w-full">
            Do you have, or do you expect to have, any employees who will receive Forms W-2 in the next 12 months?*
            (Forms W-2 require additional filings with the IRS.)
          </div>
          <div class="flex items-center space-x-8 mt-2">
            <div class="flex items-center">
              <label for="yes-option" class="font-medium text-gray-900 mr-2"
                     :class="{'text-red-600': form.errors['ein.question.w2']}">Yes</label>
              <input v-model="form.ein.question.w2" value="1" id="yes-option" name="w2" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>

            <div class="flex items-center">
              <label for="no-option" class="font-medium text-gray-900 mr-2"
                     :class="{'text-red-600': form.errors['ein.question.w2']}">No</label>
              <input v-model="form.ein.question.w2" value="0" id="no-option" name="w2" type="radio"
                     class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
          </div>

          <div v-if="form.errors['ein.question.w2']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.question.w2']"
                  :key="index">{{
                  error
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>


      <!--      <div class="sm:col-span-2 mt-6">-->
      <!--        <h2 class="text-lg font-medium mt-2">11. Signature : Employer Identification Number Application Form-->
      <!--          Signature. </h2>-->
      <!--        <p>By signing this form you are attesting that you are the duly authorized person and the information provided-->
      <!--          by you is correct. You authorize a representative of Business Certificate Services Florida LLC to act as a-->
      <!--          Third-Party Designee with the IRS during the application process only. All relevant application forms will be-->
      <!--          sent to you immediately prior to submission.</p>-->
      <!--      </div>-->

      <!--      <div class="sm:col-span-2">-->
      <!--        <div class="space-y-5">-->
      <!--          <div-->
      <!--              class="bg-white block w-full rounded-md border-gray-200 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"-->
      <!--              :class="{'border-2 border-red-500': form.errors['ein.signature_fss4']}">-->
      <!--            <VueSignaturePad width="100%" height="300px" ref="signaturePadFss4"/>-->

      <!--            &lt;!&ndash; Flex Container for Buttons &ndash;&gt;-->
      <!--            <div class="flex justify-center space-x-6 mt-2 mb-4">-->
      <!--              <button @click="clearSignaturePadFss4" type="button" class="btn" data-dismiss="modal">Clear</button>-->
      <!--              <button-->
      <!--                  v-if="form.signature_fss4==null"-->
      <!--                  @click="saveSignaturePadFss4" type="button"-->
      <!--                  class="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">-->
      <!--                Accept Signature-->
      <!--              </button>-->
      <!--            </div>-->

      <!--          </div>-->
      <!--          <div v-if="form.errors['ein.signature_fss4']" class="m-2">-->
      <!--            <ul>-->
      <!--              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.signature_fss4']"-->
      <!--                  :key="index">{{-->
      <!--                  error-->
      <!--                }}-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->


      <!--      <div class="sm:col-span-2 mt-6">-->


      <!--        <h2 class="text-lg font-medium mt-2">12. Signature : Authorization to Submit the EIN application to the IRS on-->
      <!--          your behalf. </h2>-->
      <!--        <p>By signing this form you authorize Business Certificate Services Florida LLC to submit the EIN application on-->
      <!--          your behalf. You also authorize Business Certificate Services Florida LLC to represent you in matters related-->
      <!--          to the EIN application and results with the IRS. This authorization only extends until the successful-->
      <!--          completion of the EIN application process.</p>-->
      <!--      </div>-->

      <!--      <div class="sm:col-span-2">-->
      <!--        <div class="space-y-5">-->
      <!--          <div-->
      <!--              class="bg-white block w-full rounded-md border-gray-200 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"-->
      <!--              :class="{'border-2 border-red-500': form.errors['ein.signature_f8821']}">-->
      <!--            <VueSignaturePad width="100%" height="300px" ref="signaturePadF8821"/>-->

      <!--            &lt;!&ndash; Flex Container for Buttons &ndash;&gt;-->
      <!--            <div class="flex justify-center space-x-6 mt-2 mb-4">-->
      <!--              <button @click="clearSignaturePadF8821" type="button" class="btn" data-dismiss="modal">Clear</button>-->
      <!--              <button-->
      <!--                  v-if="form.signature_f8821==null"-->
      <!--                  @click="saveSignaturePadF8821" type="button"-->
      <!--                  class="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">-->
      <!--                Accept Signature-->
      <!--              </button>-->
      <!--            </div>-->

      <!--          </div>-->
      <!--          <div v-if="form.errors['ein.signature_f8821']" class="m-2">-->
      <!--            <ul>-->
      <!--              <li class="text-red-600 text-sm" v-for="(error, index) in form.errors['ein.signature_f8821']"-->
      <!--                  :key="index">{{-->
      <!--                  error-->
      <!--                }}-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->


    </div>

  </div>

</template>

